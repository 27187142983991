import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CreateLinkRequestModalComponent } from '../create-link-request-modal/create-link-request-modal.component';

@Component({
    selector: 'app-create-link-request-button',
    imports: [TranslateModule],
    templateUrl: './create-link-request-button.component.html',
    styleUrl: './create-link-request-button.component.scss',
})
export class CreateLinkRequestButtonComponent {
    constructor(private dialog: MatDialog) {}

    onLinkRequestClick(): void {
        this.dialog.open(CreateLinkRequestModalComponent);
    }
}
