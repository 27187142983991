import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AlertSeverityComponent } from '../../../alerts/components/alert-severity/alert-severity.component';
import { FormatPipe } from '../../../shared/pipes/format.pipe';
import { SiteIconComponent } from '../../../site-detail/components/icon/site-icon.component';
import { FavoriteOID } from '../../models/favorite';
import { FavoriteSite } from '../../models/favorite-site';
import { FavoriteSiteService } from '../../store/api/favorite-site.service';

@Component({
    selector: 'app-favorite-item',
    imports: [SiteIconComponent, AlertSeverityComponent, FormatPipe, RouterLink],
    templateUrl: './favorite-item.component.html',
    styleUrl: './favorite-item.component.scss',
})
export class FavoriteItemComponent {
    @Input() favoriteSite: FavoriteSite;

    constructor(private favoriteSiteService: FavoriteSiteService) {}

    onDeleteFavorite($event: Event, favoriteOID: FavoriteOID): void {
        $event.stopPropagation();
        $event.preventDefault();

        this.favoriteSiteService.removeFavorite(favoriteOID).subscribe();
    }
}
