import { ChangeDetectorRef, Component } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, finalize, timeout } from 'rxjs/operators';
import { ToasterMessageType, ToasterSuccessMessage } from '../../../shared/models/Toaster';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ErrorService } from '../../../shared/services/error.service';
import { addToasterMessage } from '../../../shared/store/actions/addToasterMessage';
import { AppState } from '../../../shared/store/app-state';
import { GatewayId } from '../../../site-detail/models/Gateway';
import { collectErrors } from '../../../utils';
import { LinkRequestsService } from '../../store/api/link-requests.service';

enum LinkRequestErrorCodes {
    ALREADY_LINKED = 'The pro user is already linked',
    COUNTRY_LINK_UNAUTHORIZED = 'The country link is not authorized',
    WRONG_ASSOCIATION = 'The association is wrong',
}

const linkRequestErrorToMessage = {
    [LinkRequestErrorCodes.ALREADY_LINKED]: 'MODAL_ATTACH_GATEWAY_ALREADY_LINKED',
    [LinkRequestErrorCodes.WRONG_ASSOCIATION]: 'MODAL_ATTACH_GATEWAY_WRONG_ASSOCIATION',
    [LinkRequestErrorCodes.COUNTRY_LINK_UNAUTHORIZED]: 'MODAL_ATTACH_GATEWAY_COUNTRY_LINK_UNAUTHORIZED',
};

interface CreateLinkRequestForm {
    gatewayId: FormControl<string>;
    siteOwnerEmail: FormControl<string>;
}

@Component({
    selector: 'app-link-request-modal',
    templateUrl: './create-link-request-modal.component.html',
    styleUrls: ['./create-link-request-modal.component.scss'],
    imports: [
        MatDialogTitle,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class CreateLinkRequestModalComponent {
    formGroup: FormGroup<CreateLinkRequestForm>;
    formError: any;
    loading = false;
    attachRequestErrorCode?: string;

    constructor(
        public dialogRef: MatDialogRef<CreateLinkRequestModalComponent>,
        private formBuilder: UntypedFormBuilder,
        private errorService: ErrorService,
        private linkRequestsService: LinkRequestsService,
        private changeDetectorRef: ChangeDetectorRef,
        private store: Store<AppState>,
        private analyticsService: AnalyticsService,
    ) {
        this.attachRequestErrorCode = undefined;

        this.formGroup = this.formBuilder.group({
            gatewayId: ['', Validators.required],
            siteOwnerEmail: ['', Validators.compose([Validators.required, Validators.email])],
        });

        this.formGroup.valueChanges.subscribe(() => {
            this.formError = collectErrors(this.formGroup);
        });
    }

    submit(): void {
        this.loading = true;
        this.analyticsService.boxLinkingRequested();
        this.createLinkRequest(this.formGroup.value.gatewayId, this.formGroup.value.siteOwnerEmail);
    }

    cancel(): void {
        this.dialogRef.close();
        this.formGroup.reset();
    }

    generateLocalizedErrorKey(): string {
        return linkRequestErrorToMessage[this.attachRequestErrorCode] || 'MODAL_ATTACH_GATEWAY_UNKNOWN_ERROR';
    }

    private createLinkRequest(gatewayId: GatewayId, siteOwnerEmail: string) {
        return this.linkRequestsService
            .createLinkRequest({ siteOwnerEmail, gatewayId })
            .pipe(
                timeout(10 * 1000),
                catchError((err) => {
                    console.error(err);
                    this.attachRequestErrorCode = err?.error?.message as LinkRequestErrorCodes;
                    if (!this.attachRequestErrorCode) {
                        this.errorService.showToasterError('ERROR_WHILE_SENDING_SITE_ASSIGNATION_REQUEST');
                    }

                    this.changeDetectorRef.detectChanges();
                    return throwError(err);
                }),
                finalize(() => {
                    this.loading = false;
                }),
            )
            .subscribe(() =>
                this.successCallback(
                    'CMS_SITE_ASSIGNATION_TOASTER_SUCCESS_TITLE',
                    'CMS_SITE_ASSIGNATION_TOASTER_SUCCESS_MESSAGE',
                ),
            );
    }

    private successCallback(toasterTitle: string, toasterMessage: string): void {
        const successMessage: ToasterSuccessMessage = {
            id: '',
            content: {
                successMessage: toasterMessage,
                title: toasterTitle,
            },
            type: ToasterMessageType.CONFIRM_SUCCESS,
        };

        this.store.dispatch(addToasterMessage({ message: successMessage }));
        this.dialogRef.close();
        this.changeDetectorRef.detectChanges();
    }
}
