<div class="seg-modal">
  <div class="seg-modal-header">
    <h4 mat-dialog-title class="seg-modal-title">
      {{ 'ATTACH_ALREADY_ACTIVATED_GATEWAY' | translate }}
    </h4>
  </div>
  <mat-dialog-content class="seg-modal-body">
    <form autocomplete="off" [formGroup]="formGroup">
      <p> {{ 'CRITERIA_SEARCH_PIN_CODE' | translate }} :</p>
      <input class="pin-code"
        autocomplete="off"
        formControlName="gatewayId"
        name="gatewayId"
        id="gatewayId"
        type="text"
        [dropSpecialCharacters]="false"
        mask="0000-0000-0000"
        [showMaskTyped]="true"
        placeHolderCharacter="0"
        />

      <p>{{ 'MODAL_ATTACH_GATEWAY_TEXT2' | translate }} :</p>
      <input required
        class="email-input"
        type="email"
        id="siteOwnerEmail"
        formControlName="siteOwnerEmail"
        name="siteOwnerEmail"
        />

      <p class="bottom">{{ 'MODAL_ATTACH_GATEWAY_TEXT3' | translate }}</p>
    </form>

    @if (attachRequestErrorCode) {
      <div class="modal-errorMessage">
        <p class="modal-errorMessage-text">{{ generateLocalizedErrorKey() | translate }}</p>
      </div>
    }
  </mat-dialog-content>
  <mat-dialog-actions class="seg-flex-justify-space-around">
    <button id="btn-cancel"
      class="seg-btn seg-bg-color-secondary"
      mat-button
      (click)="cancel()"
    >{{ 'CANCEL' | translate }}</button>
    <button id="btn-validate"
            class="seg-btn seg-bg-color-primary"
            mat-button
            [disabled]="!formGroup.valid || loading"
            (click)="submit();"
    >{{ 'VALIDATE' | translate }}</button>
  </mat-dialog-actions>
</div>
